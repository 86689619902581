import { serverEnv } from "@/wab/env";
import type { DevFlagsType } from "@/wab/devflags";

/**
 * DevFlags.
 *
 * @description Because "DEFAULT_DEVFLAG" (overriden) is loaded server-side, "process.env" will be used in some part to fill the flags with environment variables.
 * Also because some files imports "DEFAULT_DEVFLAG" to use it as its own object, "DEFAULT_DEVFLAG" is accessible in the Plasmic public source js files.
 * You MUST NOT use secrets in this files as they will be publicly exposed.
 */
export const DEFAULT_DEVFLAG_WA_OVERRIDES: Partial<DevFlagsType> = {
  appAuth: false,
  coreTeamDomain: serverEnv.coreTeamDomain,
  githubAppName: serverEnv.reactAppGithubAppName,
  githubClientId: serverEnv.reactAppGithubClientId,
  globalTrustedHosts: ["http://localhost:3000", "http://localhost:3001"],
  hideBlankStarter: true,
  hideStartersForUsers: [],
  imgOptimizerHost: serverEnv.imgOptimizerHost,
};
